import React, { useState } from 'react';
import { Search, ChevronDown, Copy, Check, ExternalLink } from 'lucide-react';

interface User {
  fullName: string | null;
  location: string | null;
  city: string | null;
  bio: string | null;
  summary: string | null;
  socials: Record<string, string>;
  techStack: string[];
  emails: string[];
  avatarUrl: string;
  githubUrl: string;
}

const sampleData: { users: User[] } = {
  "users": [
    
    
  ]
};

const App: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [users, setUsers] = useState<User[]>(sampleData.users);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [locationFilter, setLocationFilter] = useState<string>('All');
  const [techStackFilter, setTechStackFilter] = useState<string>('All');
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState<boolean>(false);
  const [copiedEmails, setCopiedEmails] = useState<Record<string, string | null>>({});

  const API_URL = 'https://a6rqco.buildship.run/tester';

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      setUsers(sampleData.users);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}?prompt=${encodeURIComponent(searchTerm)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      
      if (data && Array.isArray(data.users)) {
        setUsers(data.users);
      } else {
        throw new Error('Unexpected data format received');
      }
    } catch (error: unknown) {
      console.error('Error fetching data:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
      setUsers(sampleData.users);
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user => 
    (locationFilter === 'All' || user.location === locationFilter) &&
    (techStackFilter === 'All' || (user.techStack && user.techStack.includes(techStackFilter)))
  );

  const uniqueLocations = ['All', ...Array.from(new Set(users.map(user => user.location || 'Unknown')))];
  const uniqueTechStacks = ['All', ...Array.from(new Set(users.flatMap(user => user.techStack || [])))];

  const handleCopyEmail = (email: string, userId: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email).then(() => {
        setCopiedEmails(prev => ({ ...prev, [userId]: email }));
        setTimeout(() => {
          setCopiedEmails(prev => ({ ...prev, [userId]: null }));
        }, 2000);
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 font-sans">
      <nav className="bg-gray-800 p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold text-purple-400">SearchTalent.dev</div>
          <div className="flex items-center space-x-4">
            <a href="#" className="hover:text-purple-300">Search History</a>
            <div className="relative">
              <button 
                onClick={() => setIsAccountDropdownOpen(!isAccountDropdownOpen)}
                className="flex items-center hover:text-purple-300"
              >
                Account <ChevronDown size={20} className="ml-1" />
              </button>
              {isAccountDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-md shadow-xl z-20">
                  <a href="#" className="block px-4 py-2 text-sm hover:bg-gray-600">Edit Account</a>
                  <a href="#" className="block px-4 py-2 text-sm hover:bg-gray-600">Log Out</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-extrabold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">AI Tech Talent Sourcer</h1>
        
        <div className="bg-gray-800 rounded-xl shadow-2xl p-8 mb-12 backdrop-blur-sm backdrop-filter">
          <h2 className="text-3xl font-bold mb-6 text-center text-purple-300">Describe Your Ideal Candidate</h2>
          <div className="flex items-center mb-6">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Specify skills and location, e.g., Python Developer in Montreal"
              className="flex-grow p-4 bg-gray-700 border-2 border-gray-600 rounded-l-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300 placeholder-gray-400"
            />
            <button 
              onClick={handleSearch}
              className="p-4 bg-purple-600 rounded-r-full hover:bg-purple-700 transition-colors duration-300"
            >
              <Search size={24} />
            </button>
          </div>

          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <select
              value={locationFilter}
              onChange={(e) => setLocationFilter(e.target.value)}
              className="p-3 bg-gray-700 border-2 border-gray-600 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300 text-gray-300"
            >
              <option value="All">Location: All</option>
              {uniqueLocations.map(location => (
                <option key={location} value={location}>{location}</option>
              ))}
            </select>
            <select
              value={techStackFilter}
              onChange={(e) => setTechStackFilter(e.target.value)}
              className="p-3 bg-gray-700 border-2 border-gray-600 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300 text-gray-300"
            >
              <option value="All">Tech Stack: All</option>
              {uniqueTechStacks.map(tech => (
                <option key={tech} value={tech}>{tech}</option>
              ))}
            </select>
          </div>
        </div>

        {loading && (
          <div className="mb-8">
            <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
              <div
                className="h-full bg-purple-600 animate-pulse"
                style={{width: '100%'}}
              />
            </div>
          </div>
        )}

        {error && (
          <div className="mb-8 p-4 bg-red-500 text-white rounded-lg">
            Error: {error}
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredUsers.map((user, index) => (
            <div key={index} className="bg-gray-800 p-4 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl border border-gray-700 text-sm">
              <div className="flex items-center mb-2">
                <img src={user.avatarUrl} alt={user.fullName || 'User'} className="w-10 h-10 rounded-full mr-3" />
                <div>
                  <h3 className="font-bold text-purple-300">{user.fullName || 'Anonymous User'}</h3>
                  <p className="text-gray-400 text-xs">{user.location || 'Unknown Location'}</p>
                </div>
              </div>

              <p className="text-xs mb-2 text-gray-300">{user.summary || user.bio || 'No bio available'}</p>

              <div className="flex flex-wrap mb-2">
                {(user.techStack || []).map(tech => (
                  <span key={tech} className="mr-1 mb-1 px-2 py-1 bg-gray-700 text-purple-300 rounded-full text-xs">
                    {tech}
                  </span>
                ))}
              </div>

              <div className="mb-2">
                {(user.emails || []).map((email, emailIndex) => (
                  <div key={emailIndex} className="flex items-center mb-1">
                    <button
                      onClick={() => handleCopyEmail(email, `${index}-${emailIndex}`)}
                      className="p-1 bg-purple-600 rounded hover:bg-purple-700 transition-colors duration-300 mr-2"
                      title="Copy email"
                    >
                      {copiedEmails[`${index}-${emailIndex}`] === email ? (
                        <Check size={12} />
                      ) : (
                        <Copy size={12} />
                      )}
                    </button>
                    <span className="text-xs text-gray-300 truncate" title={email}>{email}</span>
                  </div>
                ))}
              </div>

              <div className="flex justify-between items-center">
                {Object.entries(user.socials || {}).map(([platform, url]) => (
                  <a
                    key={platform}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-xs text-purple-400 hover:text-purple-300 transition-colors duration-300"
                  >
                    {platform}
                  </a>
                ))}
                {user.githubUrl && (
                  <a 
                    href={user.githubUrl} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-purple-400 hover:text-purple-300 transition-colors duration-300"
                    title="GitHub Profile"
                  >
                    <ExternalLink size={14} />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;